import { zodResolver } from '@hookform/resolvers/zod'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { DOMAttributes } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ExternalRoutes, Routes } from 'routes'
import handleFormError from 'utils/handleFormError'

import * as S from './styles'
import schema, { FormProps } from './validator'

export interface LoginFormProps {
  onSubmit: (data: FormProps) => Promise<void>
  onGoToRegister: () => void
  isLoading?: boolean
}

const LoginForm = ({ onSubmit, onGoToRegister, isLoading }: LoginFormProps) => {
  const { t } = useTranslation(['auth'])

  const {
    register,
    formState: { errors, isSubmitted, isValid },
    handleSubmit,
    setError,
    trigger,
  } = useForm<FormProps>({ resolver: zodResolver(schema) })

  const onFormValid: SubmitHandler<FormProps> = data => {
    onSubmit(data)
      .catch(error => handleFormError(error, setError))
      .finally(trigger)
  }

  const handleGoToRegister: DOMAttributes<HTMLButtonElement>['onClick'] =
    event => {
      event.preventDefault()
      onGoToRegister()
    }

  return (
    <S.Form onSubmit={handleSubmit(onFormValid)}>
      <S.EmailField
        name="email"
        label={t('email')}
        placeholder={t('email-placeholder')}
        register={register}
        errors={errors}
      />
      <S.PasswordField
        name="password"
        label={t('password')}
        placeholder={t('password-placeholder')}
        register={register}
        errors={errors}
      />
      <Link href={Routes.ForgotPassword} passHref>
        <S.ForgotPasswordLink>{t('forgot-password')}?</S.ForgotPasswordLink>
      </Link>
      <S.SubmitButton disabled={isLoading || (isSubmitted && !isValid)}>
        {t('log-in')}
      </S.SubmitButton>
      <S.JoinUsButton onClick={handleGoToRegister}>
        {t('join-us')}
      </S.JoinUsButton>
      <Link href={ExternalRoutes.Support} passHref>
        <S.GetHelpLink>{t('get-help-right-away')}.</S.GetHelpLink>
      </Link>
    </S.Form>
  )
}

export default LoginForm

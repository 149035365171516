import { StyledComponent } from '@emotion/styled'
import Button from 'components/Common/Button'
import InputField, { InputFieldProps } from 'components/Form/InputField'
import tw, { styled } from 'twin.macro'

import { FormProps } from './validator'

export const Form = styled.form([tw`w-full flex flex-wrap gap-4`])

export const EmailField = styled(InputField)([
  tw`w-full mt-4`,
]) as StyledComponent<InputFieldProps<FormProps>>

export const PasswordField = styled(InputField)([
  tw`w-full`,
]) as StyledComponent<InputFieldProps<FormProps>>

export const ForgotPasswordLink = styled.a([
  tw`text-brand-secondary-100 text-xs font-semibold text-right cursor-pointer ml-auto`,
  tw`hover:text-brand-secondary-40 duration-150`,
])

export const SubmitButton = styled(Button)([tw`w-full mt-2`])

export const JoinUsButton = styled(Button)([tw`w-full mb-2`])

export const GetHelpLink = styled.a([
  tw`text-brand-secondary-100 text-sm underline font-montserrat-medium text-center cursor-pointer m-auto`,
  tw`hover:text-brand-secondary-40 duration-150`,
])

EmailField.defaultProps = {
  type: 'email',
}

PasswordField.defaultProps = {
  type: 'password',
}

SubmitButton.defaultProps = {
  type: 'submit',
  variant: 'primary',
}

JoinUsButton.defaultProps = {
  type: 'button',
  variant: 'secondary',
}

import { useLoginMutation } from 'api/auth'
import axios from 'axios'
import Background from 'components/Auth/Background'
import Content from 'components/Auth/Content'
import Footer from 'components/Auth/Footer'
import Layout from 'components/Auth/Layout'
import LoginForm from 'components/Auth/LoginForm'
import { FormProps } from 'components/Auth/LoginForm/validator'
import { useAppDispatch } from 'hooks'
import { GetStaticProps, NextPage } from 'next'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Routes } from 'routes'
import { setCredentials } from 'store/auth/slice'

const Login: NextPage = () => {
  const { t } = useTranslation(['auth'])
  const dispatch = useAppDispatch()
  const router = useRouter()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [login, { isLoading }] = useLoginMutation()

  const submit = async (data: FormProps) => {
    if (!executeRecaptcha) {
      return
    }

    try {
      const token = await executeRecaptcha()
      await axios.post('/api/recaptcha', {
        token,
      })

      const credentials = await login({
        ...data,
        accountType: 'driver',
      }).unwrap()
      dispatch(setCredentials(credentials))
      router.replace(Routes.MyAccount)
    } catch (error) {
      console.log(error)
    }
  }

  const goToRegister = () => router.push(Routes.Register)

  return (
    <Layout title={t('title')}>
      <Background>
        <Content title={t('title')}>
          <LoginForm
            onSubmit={submit}
            onGoToRegister={goToRegister}
            isLoading={isLoading}
          />
        </Content>
        <Footer />
      </Background>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale || 'en', ['auth', 'validation'])),
  },
})

export default Login

import { LoginRequest } from 'api/auth/types'
import { INVALID_EMAIL, REQUIRED } from 'utils/constants'
import { z } from 'zod'

const schema: z.ZodType<Omit<LoginRequest, 'accountType'>> = z.lazy(() =>
  z.object({
    email: z.string().min(1, REQUIRED).email(INVALID_EMAIL),
    password: z.string().min(1, REQUIRED),
  }),
)

export type FormProps = z.infer<typeof schema>

export default schema
